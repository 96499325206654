@import "../../../../common/colors";

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.postImage{
  min-height: 630px;
  
}
.buttonContainer {
  
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: center;
  &>button{
    border-radius: 4px;
    color: $white;
    padding: 15px 40px;
    margin-right: 40px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }

  .reject {
        background-color: $red-1;
  }
  .approve {
    
    background-color: $blue-3;
    
  }
}
