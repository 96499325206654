@import "../../../../common/colors";

.main {
  display: flex;
  flex: 4;
  flex-direction: column;
  align-items: flex-start;

  .title {
    font-size: 16px;
    font-weight: 600;

  }

  .content {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .item {
    margin-bottom: 5px;
    font-size: 14px;
  }
}

.greenText {
  color: $text_green_1;
  font-size: 16px;
  font-weight: 600;
}

.redText {
  color: $red-1;
  font-size: 16px;
  font-weight: 600;
}

.orangeText {
  color: $orange;
  font-size: 16px;
  font-weight: 600;
}