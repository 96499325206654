.main {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: space-between;
}

.secondCol {
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  max-width: 50%;
}

.thirdCol {
  display: flex;
  flex-direction: column;
  gap: 20pxs;
}

@media(max-width:900px) {
  .main {
    flex-direction: column;
  }

  .secondCol {
    flex-direction: column;
  }
}