.main {
  width: "100%";
  display: flex;
  justify-content: start;
  flex-direction: column;
}

.title {
  display: flex;
  justify-content: start;
  margin-bottom: 20px;

  &>span {
    font-size: 20px;
  }
}

.paper {
  width: 100%;
  margin-bottom: 4px;

}

.table {
  // min-Width: 750px,
}

.visuallyHidden {
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: 'hidden';
  padding: 0;
  position: 'absolute';
  top: 20px;
  width: 1px;
}