.main {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.title {
    font-size: 20px;
}

.datePicker {
    margin: 0px;
}