.main {
    width: 100%;
    box-sizing: border-box;
}

.charts {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

    &>div {
        width: 50%;
    }
}
.charts1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

    &>div {
        width: 50%;
    }
}