@import "../../../../common/colors";
.main{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding:0px 20px;
    background-color:$blue-2;
    font-size: 16px;
    color: white;
    gap: 20px;
    .left{
      flex:1;
      display: flex;
      flex-direction: row;
      align-items: center;
      &>img{
        margin-right: 20px;
        width: 35px;
      }

      & > span {
        cursor: pointer;
        opacity: 30%;
        margin-right: 20px;
      }
  
      &__active {
        opacity: 100% !important;
      }
    }
    .right{
      display: flex;
      flex-direction: row;
      align-items: center;
      &>span{
        margin-right: 10px;
      }
      &>img{
        width:35px
      }
    }

    
  }