@import "../../../../../common/colors";

.main {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
  
  
    .options {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
  
      .option {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        align-items: center;
        & > img {
         width:190px;
         height: 416px;
        }
        .borderRight{
          border:1px solid $white
        }

        .caption{
          display: flex;
          align-items: center;
          box-sizing: border-box;
          width: 95%;
          padding: 5px;
          border-radius: 5px;
          background-color: $bg-light-1;
        }

        .label{
          font-size: 16px;
          font-weight: 500;
        }
        .percent{
          font-size: 18px;
          font-weight: 700;
          color:$gray-1
        }
      }
    }
  }
  