.main{
    width: 100%;
    display: flex;
    flex-direction: column;
   
}
.content{
    display: flex;
    flex-direction: column;
    padding: 10px 40px;
    box-sizing: border-box;
}