@import "../../../../../common/colors";

.main {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    box-sizing: border-box;
    height: 100px;
}

.filtersContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    color: black;
    margin-right: 40px;
}

.filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    &>span:not(:last-child) {
        border-right: 1px solid red;
    }
}

.filterItem {
    color: red;
    cursor: pointer;
    padding-right: 5px
}

.activeFilterItem {
    color: $blue-3 ;
}

.datepickerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex: 1;
}