@import "../../common/colors";

.main {
  width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    padding: 20px 40px;
    background-color: $blue-2;

    &>img {
      width: 100px;
    }
  }

  .logo {
    &>img {
      width: 200px;
    }
  }

  .form1 {
    display: flex;
    flex-direction: column;
    gap: 10;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    &>div {
      width: 320px;
      margin-bottom: 20px;
    }
  }

  .button {
    border: none;
    background-color: $blue-3;
    padding: 15px 40px;
    border-radius: 6px;
    width: 100%;
    color: white;
    cursor: pointer;
  }
}