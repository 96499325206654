@import "../../../../common/colors";

.main {
  display: flex;
  flex-direction: column;
  flex: 4;
  align-items: flex-start;
  .title {
    font-size: 16px;
    font-weight: 600;
  }

  .content {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .category {
    width: 21%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px;
    font-size: 12px;
    color: $gray-1;
    & > img {
      width: 25px;
      height: 25px;
      margin-bottom: 5px;
    }
  }
  .activeCategory{
    background:rgba($color: $blue-1, $alpha: 0.22);
    color: $blue-3;
    border-radius: 5px;
  }

  
}
