@import "../../../../../common/colors";

.main {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
  
    .options {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: center;
     
  
      .option {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 34%;
     
        box-sizing: border-box;
        margin-bottom: 15px;
        & > img {
          width:150px;
          height: 150px;
        }

        .label{
          font-size: 14px;
          font-weight: 500;
        }
        .percent{
          font-size: 18px;
          font-weight: 700;
          color:$gray-1
        }
      }
    }
  }
  