
@import "../../../../../common/colors";
.main {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  
    .image {
      height: 80px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      & > img {
        height: 100%;
      }
    }
  
    .options {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      box-sizing: border-box;
  
      .option {
        background-color: $bg-light-1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        padding:10px;
        margin-bottom: 10px;
        box-sizing: border-box;
        border-radius: 5px;
        

        .label{
          font-size: 16px;
          font-weight: 500;
        }
        .percent{
          font-size: 18px;
          font-weight: 700;
          color:$gray-1
        }
      }
    }
  }
  