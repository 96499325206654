@import "../../../../../common/colors";

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;

  .image {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      height: 100%;
    }
  }

  .options {
    display: flex;
    flex-direction: column;

    .option {
      display: flex;
      flex-direction: column;
      padding: 5px;
      margin-bottom: 10px;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;

      & > img {
        width: 375px;
        height: 220px;
      }

      .caption {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        width: 375px;

        .labelBox {
          padding: 5px;
          background-color: $bg-light-1;
          border-radius: 5px;
          width: 250px;
          text-align: start;
        }
      }

      .label {
        font-size: 16px;
        font-weight: 500;
      }
      .percent {
        font-size: 18px;
        font-weight: 700;
        color:$gray-1
      }
    }
  }
}
