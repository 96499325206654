$blue-1: #c3dbf7;
$blue-2: #93c4fa;
$blue-3: #4f95e5;
$bg-light-1: #f3f9ff;
$white: #ffffff;
$red-1: #f454b4;
$gray-1: #9194AE;
$gray-2: #57575b;
$orange: #F0B603;

$text_green_1: #159f28;