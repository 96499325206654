.main {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 100px);
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-left: 60px;
  padding-right: 60px;

  &>div {
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    cursor: pointer;
  }

  &>input {
    box-sizing: border-box;
    width: 180px;
    padding: 5px 10px;
    border: none;
    border-radius: 6px;
    outline: none;
    border: 1px solid #ccc;
    margin-right: 10px;
  }
}