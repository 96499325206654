.main{
    width: 60px;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    
    padding: 40px 5px;
    &>div{
      &>img{
        width: 40px;
        margin-bottom: 15px;
      }
    }
  }