@import "../../../../../../../common/colors";

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.parameters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: fit-content;
  max-width: 1440px;
  width: 100%;
}

.parameterItem {
  display: flex;
  flex-direction: column;
  width: 16%;
  margin-bottom: 40px;

  &__value {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__label {
    font-size: 16px;
    font-weight: 400;
    color: $gray-1;
    width: 60%;
    align-self: center;
  }

  &__subLabel {
    font-size: 13px;
    font-weight: 400;
    color: $gray-1;
  }
}

.secondParameters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 2;
}

.secondParameterItem {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 2px;
  margin: 10px;

  &__value {
    font-size: 32px;
    font-weight: 700;
  }

  &__label {
    font-size: 22px;
    font-weight: 400;
  }
}