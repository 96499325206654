@import "../../../../common/colors";

.main {
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: center;

  &>img:first-child {
    width: 100px;
    margin-bottom: 20px;
  }

  &>img:nth-child(2) {
    width: 60px;
    margin-bottom: 20px;
  }

  .button {
    color: $white;
    margin-top: 20px;
    border-radius: 4px;
    padding: 10px 15px;
    border: none;
    background-color: $gray-1;
    cursor: pointer;
  }

  .activeButton {
    color: $white;
    margin-top: 20px;
    border-radius: 4px;
    padding: 10px 15px;
    border: none;
    background-color: $blue-3;
    cursor: pointer;
  }
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: white;
  margin-bottom: 10px;
}

.restoreImg {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.nextButton {
  color: $white;
  border-radius: 4px;
  padding: 10px 15px;
  border: none;
  background-color: $orange;
  cursor: pointer;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.blocked {
  width: 100px;
  height: 40px;
  border-radius: 10px;
  text-align: center;
  color: $white;
  background-color: $red-1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 300px;
  justify-content: space-between;
}