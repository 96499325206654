.main {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.left {
  display: flex;
  flex: 4;
}
.right {
  display: flex;
  flex: 5;
  flex-direction: column;
  padding-top: 20px;

  .top {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media(max-width:900px){
  .main{
    flex-direction: column;
  }
}
@media(max-width:600px){
  .main{
    .right{
      .top{
        flex-direction: column;
      }
      .bottom{
        flex-direction: column;
      }
    }
  }
}
