@import "../../../../../common/colors";
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  .image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    & > img {
      width: 375px;
      height: 400px;
    }
  }

  .options {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 375px;
    .option {
      display: flex;
      width: 49%;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
      box-sizing: border-box;
      background-color: $bg-light-1;
      padding: 5px;
      border-radius: 5px;
      .label {
        font-size: 14px;
        font-weight: 500;
      }
      .percent {
        color:$gray-1;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
}
